// extracted by mini-css-extract-plugin
export var active = "index-module--active--f01cb";
export var banner_container = "index-module--banner_container--3a6c4";
export var detail = "index-module--detail--e49ba";
export var double_title = "index-module--double_title--cb746";
export var flex_box = "index-module--flex_box--70610";
export var flex_item = "index-module--flex_item--676d2";
export var icon = "index-module--icon--93828";
export var intro = "index-module--intro--f4c47";
export var jump_container = "index-module--jump_container--f6bce";
export var jump_to = "index-module--jump_to--b5b3a";
export var mobile_title = "index-module--mobile_title--0ebe1";
export var render_item = "index-module--render_item--ba761";
export var second_title = "index-module--second_title--a77f7";
export var technology_products = "index-module--technology_products--9fa39";
export var title = "index-module--title--af201";